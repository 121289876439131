import moment from "moment"
import React from "react"
import { lng, setLang } from "../components/essentials"
import Layout from "../components/layout"
import PublicationCard from "../components/publications/publication-card"
import SEO from "../components/seo"
import { publications } from "../data"

// english
// arabic

export default function Publications(props) {
  setLang(props.lang || "en")

  return (
    <Layout lang={lng("en", "ar")}>
      <SEO title={lng("Publications", "المقالات العلمية")} />
      <main>
        <section style={{ paddingTop: "200px" }}>
          <div className="standard-container">
            <h2 style={{ color: "#fff" }} className="mb5 underlined-title">
              {lng("Publications", "المقالات العلمية")}
            </h2>
            {publications.map(article => (
              <PublicationCard data={article} />
            ))}
          </div>
        </section>
      </main>
    </Layout>
  )
}
