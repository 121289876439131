import React from "react"

const placements = {
  "top-left": ["start", "start"],
  "top-center": ["start", "center"],
  "top-right": ["start", "end"],
  "center-left": ["center", "start"],
  "center-center": ["center", "center"],
  "center-right": ["center", "end"],
  "bottom-left": ["end", "start"],
  "bottom-center": ["end", "center"],
  "bottom-right": ["end", "end"],
}

export default function NoStretchImage(props) {
  const { src, className, style } = props
  let { placement } = props
  placement = (placement && placements[placement]) || ["center", "center"]

  return (
    <div
      style={{
        alignItems: placement[0],
        justifyContent: placement[1],
        ...style,
      }}
      className={"w-max h-max flex overflow-hidden " + className}
    >
      <img
        src={src}
        style={{
          minWidth: "100%",
          minHeight: "100%",
          width: "100%",
          height: "100%",
        }}
      />
    </div>
  )
}
