import { Link } from "gatsby"
import moment from "moment"
import React from "react"
import { useState } from "react"
import { lng } from "../essentials"
import NoStretchImage from "../no-stretch-image"

const initialAuthorsLimit = 3

export default function PublicationCard(props) {
  const { data: article } = props
  const [authorsExpanded, setAuthorsExpanded] = useState()

  const authors =
    article.authors.length > initialAuthorsLimit && !authorsExpanded
      ? article.authors
          .filter(e => e)
          .map(a => a.name)
          .slice(0, initialAuthorsLimit)
          .join(", ") + "..."
      : article.authors
          .filter(e => e)
          .map(a => a.name)
          .join(", ")

  return (
    <div
      className="w-full flex flex-column flex-row-ns mv4 justify-center justify-start-ns"
      style={{ height: "max-content" }}
    >
      <NoStretchImage
        src={(article.thumbnail?.medium || article.thumbnail)?.url}
        className="mr3 mb4 mb0-ns w-100 w-30-ns"
      />
      <div className="flex flex-column ml3 publication-card">
        <div>
          <span className="font-martian white f4">
            {article.category.title}
          </span>
          <div className="mv2">
            <a href={article.link} target="_blank" className="title-link">
              <span className="mt1 f4 f3-ns white font-nostromo fw6 text-highlight-brown">
                {article.title}
              </span>
            </a>
          </div>
          <div className="mb3">
            {authors}{" "}
            {article.authors.length > initialAuthorsLimit && (
              <button
                onClick={() => setAuthorsExpanded(!authorsExpanded)}
                className="ml3 uk-button button-white-rounded-sm"
              >
                {authorsExpanded ? "Hide authors" : "Show authors"}
              </button>
            )}
          </div>
        </div>
        <div className="flex flex-column flex-row-ns items-start items-center-ns justify-between w-100 mt2 mb4 mv0-ns">
          <div className="flex justify-between w-20-ns items-center mb2 mb0-ns">
            <a
              className="white underline"
              href={article.download_url}
              download={article.title + ".pdf"}
            >
              Download PDF
            </a>
          </div>
          <span>
            Published: {moment(article.created_at).format("DD MMMM Y")}
          </span>
          <span>
            Published in{" "}
            <a href="https://springer.com" className="link">
              Springer.com
            </a>
          </span>
        </div>
      </div>
    </div>
  )
}
